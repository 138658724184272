import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uae-free-zones',
  templateUrl: './uae-free-zones.component.html'
})
export class UAEFreeZonesComponent implements OnInit {
  
  page;
  pageLoaded: boolean = false;
  constructor(private _apiService: ApiService) { }

  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['uae-free-zones'];
      this.initializePage();
    }
  }
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page.head_tags, this.page.fields.seo_description);
  }
}
