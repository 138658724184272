import { HttpClient } from '@angular/common/http';
import { ApiService } from './../../services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ThankYouService } from 'src/app/services/thank-you.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {
  
  page;
  pageLoaded: boolean = false;
  
  formName: string = "";
  formEmail: string = "";
  formCountry: string = "";
  formPhone: string = "";
  formMessage: string = "";
  
  @ViewChild("popupContact") popup;
  @ViewChild("loader") loader;
  popupTitle;
  popupContent;
  
  
  constructor(private route: ActivatedRoute,  private _apiService: ApiService, private http: HttpClient, private router: Router, private tyService: ThankYouService) { }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['contact-us'];
      this.initializePage();
    }
  }
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page.head_tags, this.page.fields.seo_description);
    setTimeout(()=>{
      if (this.route.snapshot.fragment == 'contact-scroll') {
        window.scrollTo(0, document.getElementById('contact-scroll').offsetTop - 100);
      }
    },80)
    
  }
  
  contact() {
    this.loader.nativeElement.classList.remove('hidden');
    let element = <HTMLInputElement> document.getElementById("policy");
    this.http.get("https://levelupconsulting.ae/contact/contact.php?" +
    "name=" + this.formName + 
    "&email=" + this.formEmail + 
    "&country=" + this.formCountry + 
    "&phone=" + this.formPhone + 
    "&message=" + this.formMessage + 
    "&policy=" + element.checked
    ).subscribe(
      (response: {success: boolean, message: string}) => {
        if (response.success) {
          this.tyService.allowContactTY = true;
          this.router.navigate(['/thank-you-contact']);
        } else {
          this.popupTitle = "Oops";
          this.popupContent = response.message;
          this.popup.nativeElement.classList.add('open');
          this.loader.nativeElement.classList.add('hidden');
        }
      });
    } 
    closePopup() {
      this.popup.nativeElement.classList.remove('open');
    }
  }
  