import { Injectable, EventEmitter } from "@angular/core";
import { Response } from '@angular/http';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ApiService {
    pageLoaded: EventEmitter<any>;
    pagesLoaded: boolean = false;
    
    apiURL: string = 'https://levelupconsulting.ae/api/';
    apiVersion: string = "wp-json/wp/v2/";
    apiLoaded = {};
    apiPosts;
    typesLoaded = {};

    constructor(
        // private _cookieService: CookieService, 
        private http: HttpClient, 
        private titleService: Title, 
        private metaService: Meta,
    ) {
        this.pageLoaded = new EventEmitter;
    }
    
    getPageJSON(pageSlug: string) {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'pages?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    let temp = result.filter(obj => {
                        return obj.slug === pageSlug;
                    });
                    result = temp[0];
                    this.apiLoaded[pageSlug] = result;
                    resolve(result);
                }
            )
        });
    }
    getPages() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'pages?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    for(let i = 0; i < result.length; i++) {
                        this.apiLoaded[result[i].slug] = result[i];
                    }
                    this.pagesLoaded = true;
                    resolve(result);
                }
            )
        });
    }

    getPosts() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'posts?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    this.apiPosts = result;
                    resolve(result);
                }
            )
        });
    }
    getFreezoneJSON(freezoneSlug: string) {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'free_zone?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    let temp = result.filter(obj => {
                        return obj.slug === freezoneSlug;
                    });
                    result = temp[0];
                    this.apiLoaded['freezone-' + freezoneSlug] = result;
                    resolve(result);
                }
            )
        });
    }
    
    //NOT READY YET
    SEO(meta, description) {
        this.titleService.setTitle(meta.seo_title);
        this.metaService.updateTag({ name: 'description', content: description });
        this.metaService.updateTag({ name: 'robots', content: meta.robots });
        this.metaService.updateTag({ property: 'og:locale', content: meta.og_locale });
        this.metaService.updateTag({ property: 'og:type', content: meta.og_type });
        this.metaService.updateTag({ property: 'og:title', content: meta.og_title });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:site_name', content: meta.og_site_name });
        this.metaService.updateTag({ property: 'og:image', content: meta.og_image });
        this.metaService.updateTag({ name: 'twitter:title', content: meta.twitter_title });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        this.metaService.updateTag({ name: 'twitter:card', content: meta.twitter_card });
    }

    SEOFreeZone(meta, description, title) {
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'description', content: description });
        this.metaService.updateTag({ name: 'robots', content: meta.robots });
        this.metaService.updateTag({ property: 'og:locale', content: meta.og_locale });
        this.metaService.updateTag({ property: 'og:type', content: meta.og_type });
        this.metaService.updateTag({ property: 'og:title', content: title });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:site_name', content: meta.og_site_name });
        this.metaService.updateTag({ property: 'og:image', content: meta.og_image });
        this.metaService.updateTag({ name: 'twitter:title', content: title });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        this.metaService.updateTag({ name: 'twitter:card', content: meta.twitter_card });
    }
    apiCall(url) {
        return new Promise((resolve, reject) => {
            let result;
            this.http.get(this.apiURL + this.apiVersion + url).subscribe(
                (response: Response) => {
                    result = response.json();
                    this.typesLoaded[url] = result;
                    resolve(result);
                }
            )
        });
    }

    public lang = {
        bankAccount: {
            general: {
                hero: "General",
                hero_subheading: "Here are all types of bank accounts you can open in Dubai that are available in any part of the world.",
                content: "<p style='margin: 0;'>Here are all types of bank accounts you can open in Dubai that are available in any part of the world. Dubai has seen immense growth in two decades and has a strong economy with wide range of business sector. One of the major parts of Dubai economy and its growth in business sector has help of Dubai&rsquo;s strong banking system. Having a bank account in Dubai shows your reliability and it comes with lot of advantages.&nbsp;Bank account opening in Dubai seems easy but a lot of paper work involved in it. You need to consider carefully the bank and the type of the bank account you are willing to open. Almost every major international bank has branches all over the United Arab Emirates. Here are some important details you need to remember when deciding to open a bank account.<br /> If you are non-resident and are willing to open a bank account in Dubai, you most certainly have the option to do so. However there are some restrictions when it comes to Non-resident bank account like you would be given the check book and you can only open a saving account. You will only be provided with the ATM debit card for cash withdrawal.</p>",
            },
            corporate: {
                hero: "Corporate Bank Account",
                hero_subheading: "Requirements for opening a corporate or bank account in Dubai",
                content: ""
            },
            personal: {
                hero: "Personal Bank Account",
                hero_subheading: "Requirements for opening a personal or bank account in Dubai",
                content: ""
            },
            services: {
                hero: "Services offered by Banks",
                hero_subheading: "Services provided by commercial banks in Dubai",
                content: ""
            },
            benefits: {
                hero: "Benefits",
                hero_subheading: "Benefits of having a bank account in Dubai",
                content: ""
            }
        }
    }
}