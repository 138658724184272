import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-free-zone',
  templateUrl: './free-zone.component.html'
})
export class FreeZoneComponent implements OnInit {
  
  freezone;
  pageLoaded;
  freezoneSlug;
  @ViewChild("popupOffer") popup;
  constructor(private _apiService: ApiService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.route.params.subscribe((params)=>{
      this.toggleOfferPopup();
      this.freezoneSlug = params['freezone'];
      this._apiService.getFreezoneJSON(this.freezoneSlug).then((result) => {
        this.freezone = result;
        this.pageLoaded = true;
        this._apiService.pageLoaded.emit(true);
        this._apiService.SEOFreeZone(this.freezone.head_tags, this.freezone.fields.seo_description, this.freezone.fields.seo_title);
      });
    });
  }

  toggleOfferPopup(){
    if (this.popup) {
      this.popup.nativeElement.classList.toggle('open');
    }
  }
}