import { APIGuard } from './services/api.guard';
//Angular Stuff
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

//Custom Modules
import { AppRoutingModule } from './app.routing';



import 'hammerjs';
import  'mousetrap'; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save mousetrap`)
// import { GalleryModule } from '@ks89/angular-modal-gallery';
// import { GalleryModule } from  'ng-gallery';


//Components
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './extra/header/header.component';
import { FooterComponent } from './extra/footer/footer.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { PressComponent } from './pages/press/press.component';
import { ValueAddedServicesComponent } from './pages/value-added-services/value-added-services.component';
import { BankAccountComponent } from './pages/bank-account/bank-account.component';
import { ApiService } from './services/api.service';
import { FreeZoneCompanySetupComponent } from './pages/free-zone-company-setup/free-zone-company-setup.component';
import { FreeZoneVisasComponent } from './pages/free-zone-visas/free-zone-visas.component';
import { FreeZoneBusinessActivitiesComponent } from './pages/free-zone-business-activities/free-zone-business-activities.component';
import { PostComponent } from './pages/post/post.component';
import { ThankYouContactComponent } from './pages/thank-you-contact/thank-you-contact.component';
import { ThankYouContactGuard } from './services/thank-you-contact.guard';
import { ThankYouService } from './services/thank-you.service';
import { FreeZoneComponent } from './pages/free-zone/free-zone.component';
import { UAEFreeZonesComponent } from './pages/uae-free-zones/uae-free-zones.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    AboutComponent,
    PressComponent,
    ValueAddedServicesComponent,
    BankAccountComponent,
    FreeZoneCompanySetupComponent,
    UAEFreeZonesComponent,
    FreeZoneVisasComponent,
    FreeZoneBusinessActivitiesComponent,
    PostComponent,
    ThankYouContactComponent,
    FreeZoneComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    // GalleryModule,
  ],
  providers: [APIGuard, ApiService, ThankYouService, ThankYouContactGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
