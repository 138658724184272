import { ApiService } from './../../services/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Image } from '@ks89/angular-modal-gallery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  page;
  images: Image[];
  pageLoaded: boolean = false;
  promise;
  constructor(
    private _apiService: ApiService,
  ) { }

  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['about-us'];
      this.initializePage();
    }
  }

  initializePage(){
    this.pageLoaded = true;
    this.fillGallery();
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page.head_tags, this.page.fields.seo_description);
  }

  fillGallery() {
    let gallery = this.page.fields.gallery;
    let i = 1;
    this.images = [];
    for (let image of gallery) {
      this.images.push(
        {
          id: i,
          modal: {
            img: image.image.url
          },
          plain: {
            img: image.image.sizes.medium_large
          }
        }
      )
      i++;
    }
  }
}
