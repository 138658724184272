import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html'
})
export class PressComponent implements OnInit {
  
  page;
  posts;
  
  
  posts_shown = [];
  posts_shown_n: number = 6;
  isLoadMoreAllowed: boolean = true;
  temp;
  
  pageLoaded: boolean = false;
  constructor(private _apiService: ApiService) { }


  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['press'];
      this._apiService.getPosts().then((result) => {
        this.posts = result;
        this.posts.sort(this.sortPosts);
        this.initPosts();
        this.initializePage();
      });
    }
  }
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page.head_tags, this.page.fields.seo_description);
  }
  
  initPosts(){
    this.posts_shown = [];
    
    let temp = [];
    for (let j=0; j< this.posts.length; j++) {
      temp.push(this.posts[j]);
    }
    if (temp.length > 6) {
      this.posts_shown_n = 6;
    } else {
      this.posts_shown_n = temp.length;
      this.isLoadMoreAllowed = false;
    }
    
    //Add the first posts in top this.posts_shown;
    for (let i = 0; i < temp.length; i++) {
      if (this.posts_shown.length >= this.posts_shown_n) {
        if (this.posts_shown.length == temp.length) {
          this.isLoadMoreAllowed = false;
        } 
        return;
      }
      this.posts_shown.push(temp[i]);
    }
    
  }

  loadPosts(){
    this.temp = [];
    //Create a temporary project list of certain category;
    for (let j=0; j< this.posts.length; j++) {
      this.temp.push(this.posts[j]);
    }
    //Check if all projects have already be loaded;
    if (this.posts_shown.length < this.temp.length) {
      
      //Check if remaining posts are more or less than 6;
      var diff = this.temp.length - this.posts_shown.length;
      if (diff >= 6) {
        this.posts_shown_n+=6;
      } else {
        this.posts_shown_n = this.posts_shown_n + diff;
      }
      
      //Add the remaining posts of loading in top this.posts_shown;
      this.posts_shown = [];
      for (let i = 0; i <= this.temp.length; i++) {
        if (this.posts_shown.length == this.posts_shown_n) {
          if (this.posts_shown.length == this.temp.length) {
            this.isLoadMoreAllowed = false;
          }
          return;
        } else {
          this.posts_shown.push(this.temp[i]);
        }
      }
    } else {
      this.isLoadMoreAllowed = false;
    }
  }

  sortPosts(a,b) {
    if (a.menu_order < b.menu_order) {
      return -1;
    } else if (a.menu_order > b.menu_order) {
      return 1;
    }
  }
}