import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html'
})
export class BankAccountComponent implements OnInit {
  
  lang;
  page;
  pageLoaded;
  type;
  constructor(private _apiService: ApiService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.route.params.subscribe((params)=>{
      this.type = params['type'];
      this.lang = this._apiService.lang[this.type];
      this._apiService.getPageJSON('bank-account-' + this.type).then((result) => {
        this.page = result;
        this.initializePage();
      });
    });
  }
  initializePage() {
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page.head_tags, this.page.fields.seo_description);
  }
}
