import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  page;
  pageLoaded: boolean = false;
  constructor(private _apiService: ApiService) { }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['home'];
      this.initializePage();
    } else {
      console.log('problem with running initial api call');
    }
  }
  
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page.head_tags, this.page.fields.seo_description);
  }
}
