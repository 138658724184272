import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-thank-you-contact',
  templateUrl: './thank-you-contact.component.html'
})
export class ThankYouContactComponent implements OnInit {


  constructor(private _apiService: ApiService,  private titleService: Title ) { }

  ngOnInit() {
    this.titleService.setTitle("Thank you for contacting us - LevelUp Consulting");
    this._apiService.pageLoaded.emit(true);
  }

}
