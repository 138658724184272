import { ApiService } from './../../services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  
  @ViewChild('hamburger') hamburger;
  @ViewChild('mobileMenu') mobileMenu;
  pageLoaded: boolean = false;
  notallowactions = false;
  constructor(private _apiService: ApiService) { }
  
  ngOnInit() {
    this._apiService.pageLoaded.subscribe((status)=> {
      this.pageLoaded = status;
    });
  }
  
  toggleMenu() {
    this.hamburger.nativeElement.classList.toggle('open');
    this.mobileMenu.nativeElement.classList.toggle('open');
  }
  closeMenu() {
    this.hamburger.nativeElement.classList.remove('open');
    this.mobileMenu.nativeElement.classList.remove('open');
  }
  mobileDropdown(event: Event) {
    event.srcElement.classList.toggle('open');
  }
  openActions() {
    if (!this.notallowactions) {
      document.getElementById('actions').classList.add("open");
    }
  }
  toggleActions() {
    document.getElementById('actions').classList.toggle("open");
    this.notallowactions = true;
  }
}
