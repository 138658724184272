import { APIGuard } from './services/api.guard';
import { FreeZoneComponent } from './pages/free-zone/free-zone.component';

//Angular stuff
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

//Components
import { HomeComponent } from './pages/home/home.component';
import { BankAccountComponent } from './pages/bank-account/bank-account.component';
import { ValueAddedServicesComponent } from './pages/value-added-services/value-added-services.component';
import { PressComponent } from './pages/press/press.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FreeZoneCompanySetupComponent } from "./pages/free-zone-company-setup/free-zone-company-setup.component";
import { FreeZoneVisasComponent } from "./pages/free-zone-visas/free-zone-visas.component";
import { FreeZoneBusinessActivitiesComponent } from "./pages/free-zone-business-activities/free-zone-business-activities.component";
import { PostComponent } from "./pages/post/post.component";
import { ThankYouContactGuard } from './services/thank-you-contact.guard';
import { ThankYouContactComponent } from './pages/thank-you-contact/thank-you-contact.component';
import { UAEFreeZonesComponent } from './pages/uae-free-zones/uae-free-zones.component';

const appRoutes: Routes = [
    {
        path: '',
        canActivate: [APIGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
                pathMatch: 'full',
            },
            {
                path: 'about-us',
                component: AboutComponent,
            },
            {
                path: 'press',
                component: PressComponent,
            },
            {
                path: 'post/:slug',
                component: PostComponent,
            },
            {
                path: 'bank-account/:type',
                component: BankAccountComponent,
            },
            {
                path: 'free-zone/:freezone',
                component: FreeZoneComponent,
            },
            {
                path: 'free-zone-company-setup',
                component: FreeZoneCompanySetupComponent,
            },
            {
                path: 'uae-free-zones',
                component: UAEFreeZonesComponent,
            },
            {
                path: 'free-zone-visas',
                component: FreeZoneVisasComponent,
            },
            {
                path: 'free-zone-business-activities',
                component: FreeZoneBusinessActivitiesComponent,
            },
            {
                path: 'value-added-services',
                component: ValueAddedServicesComponent,
            },
            {
                path: 'contact-us',
                component: ContactComponent,
            },
            {
                path: 'thank-you-contact',
                component: ThankYouContactComponent,
                canActivate: [ThankYouContactGuard]
            },
        ]
    },
    {
        path: '**',
        redirectTo: '',
    }
]
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes),
    ],
    exports: [
        RouterModule,
    ],
})

export class AppRoutingModule {
    
}