import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html'
})
export class PostComponent implements OnInit {
  
  postSlug;
  post;
  pageLoaded: boolean = false;
  constructor(private route: ActivatedRoute, private _apiService: ApiService) { }
  
  ngOnInit() {
    this.route.params.subscribe((params)=>{
      this.postSlug = params['slug'];
      if (this._apiService.apiPosts) {
        let posts = this._apiService.apiPosts;
        let post = posts.filter(obj => {
          return obj.slug === this.postSlug;
        });
        this.post = post[0];
        this.pageLoaded = true;
        this._apiService.pageLoaded.emit(true);
        this._apiService.SEO(this.post.head_tags, this.post.fields.seo_description);
      } else {
        this._apiService.getPosts().then((result) => {
          let posts = this._apiService.apiPosts;
          let post = posts.filter(obj => {
            return obj.slug === this.postSlug;
          });
          this.post = post[0];
          this.pageLoaded = true;
          this._apiService.pageLoaded.emit(true);
          this._apiService.SEO(this.post.head_tags, this.post.fields.seo_description);
        });
      }
    });
  }
  
}
